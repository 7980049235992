export const ConvertObjToArray = (obj) => {
  var result = [];
  for (var i in obj) result.push([i, obj[i]]);
  return result;
};

export const sumArray = (array) => {
  let sum = 0;

  array.forEach(item => {
    sum += item;
  });

  return sum;
}

export const extractColumn = (arr, column) => {
  return arr.map(x => x[column])
}

export const arrayToFilterString = (filters) => {
  if (!filters || Object.keys(filters).length === 0) {
    return "";
  }

  const filterString = [];
  Object.entries(filters).map(([key, value]) => {
    if (value) {
      filterString.push(`${key}=${encodeURIComponent(value)}`);
    }
  });

  return (filterString.length > 0) ? `${filterString.join(";")}` : ``;
}

export const randomIndex = (array) => {
  if (array.length === 0) {
    return null;
  }
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}