<template>
  <div>
    <div class="box-filter">
      <StatusUpdateDados />
      <FilterDatepicker @getDataSelect="setDataFilter" />
    </div>

    <div class="primary">
      <div class="box">
        <HeaderGraphic :data="lineData" />
        <div class="painel">
          <LineChart :chart-data="lineData.datacollection"></LineChart>
        </div>
        <div>
          <Legenda :legendas="lineData.legendaBottom" />
        </div>
      </div>
      <div class="box">
        <HeaderGraphic :data="graphicBarData" />
        <div class="painel">
          <BarVertical :chart-data="graphicBarData.datacollection"></BarVertical>
        </div>
      </div>
      <div class="box table">
        <div class="box__header">
          <HeaderGraphic :data="tableData" />
          <a class="btn-download" @click="downloadCSV()" download>Download CSV</a>
        </div>
        <div class="painel">
          <b-table :items="tableData.items"></b-table>
          <paginate
            v-model="currentPage"
            :page-count="lastPage"
            :prev-text="'‹'"
            :next-text="'›'"
            :container-class="'pagination'"
            :page-class="'page-item'"
          ></paginate>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Legenda from "@/components/legenda/Legenda.vue";
import FilterDatepicker from "@/components/filter-datepicker/FilterDatepicker.vue";
import LineChart from "@/components/charts/LineChart.vue";
import BarVertical from "@/components/charts/BarVertical.vue";
import HeaderGraphic from "@/components/header-graphic/HeaderGraphic.vue";
import StatusUpdateDados from "@/components/status-update-dados/StatusUpdateDados.vue";
import { ConvertObjToArray } from "@/utils/array";
import { chartColors } from "@/utils/chart";
import { filterData } from "@/utils/date";
import { numberToReal } from "@/utils/money";
import { listarVendasPorCanal, listarVendasPlataformaMarketplacePorPeriodo, getCSV } from "@/api/afiliados";

export default {
  components: {
    LineChart,
    BarVertical,
    HeaderGraphic,
    FilterDatepicker,
    StatusUpdateDados,
    Legenda,
  },
  data() {
    return {
      lineData: {},
      graphicBarData: {},
      tableData: {},
      perPage: 15,
      currentPage: 1,
      start: null,
      end: null,
    };
  },
  computed: {
    ...mapState({
      accountActive: state => state.accounts.accountActive,
      getGraphicLine: state => state.vendas.vendasVtexFulfillmentPeriodo,
      getAffiliate: state => state.vendas.vendasCanal,
      lastPage: state => state.vendas.vendasCanalPorPagina_last_page,
    }),
  },
  mounted() {
    this.listarVendasPorCanal(this.start, this.start, this.perPage, this.currentPage);
    this.listarVendasPlataformaMarketplacePorPeriodo();
  },
  methods: {
    listarVendasPorCanal,
    getCSV,
    listarVendasPlataformaMarketplacePorPeriodo,
    setDataFilter({ start, end }) {
      (this.start = start), (this.end = end);
      this.listarVendasPorCanal(start, end, this.perPage, this.currentPage);
      this.listarVendasPlataformaMarketplacePorPeriodo(start, end);
    },
    downloadCSV() {
      this.getCSV(this.start, this.end);
    },
    loadGraphicLine(value) {
      const labels = [];
      const datasets = [];
      const legenda = [];

      if (value) {
        value.map((line, key) => {
          const dataFulfillment = [];
          const color = chartColors[key];
          const data = ConvertObjToArray(line.data);

          const ifLegenda = legenda.findIndex(i => i.title === line.affiliateId);
          if (ifLegenda === -1) {
            legenda.push({
              title: line.affiliateId,
              color: color,
            });
          }

          data.map(day => {

            const ifLabel = labels.findIndex(l => l === filterData(day[0]));
            if (ifLabel === -1) labels.push(filterData(day[0]));
            
            if (day[1] !== null) {
              dataFulfillment.push(day[1].valor_vendido);
            } else {
              dataFulfillment.push(0);
            }
          });

          datasets.push({
            lineTension: 0,
            borderWidth: 2,
            borderColor: color,
            pointBackgroundColor: color,
            backgroundColor: `${color}35`,
            data: dataFulfillment,
          });

        });
      }

      let data = {
        title: {
          top: "",
          name: "Acompanhamento por canal",
        },
        legendaBottom: legenda,
        datacollection: {
          labels,
          datasets: datasets,
        },
      };
      return (this.lineData = data);
    },
    loadGraphicBar(value) {
      const labels = [];
      const data = [];

      if (value.data) {
        value.data.map(line => {
          labels.push(line.affiliateId);
          data.push(line.valor_vendido);
        });
      }

      let newData = {
        title: {
          top: "",
          name: "Receita por canal",
        },
        legenda: [],
        datacollection: {
          labels,
          datasets: [
            {
              barThickness: 16,
              lineTension: 0,
              borderWidth: 1,
              borderColor: "#FD3064",
              pointBackgroundColor: "#FD3064",
              backgroundColor: "#FD3064",
              data,
            },
          ],
        },
      };

      return (this.graphicBarData = newData);
    },
    loadTable(value) {
      const items = [];

      if (value.data) {
        value.data.map(line => {
          items.push({
            canal: line.affiliateId,
            maior_pedido: numberToReal(line.maior_pedido),
            menor_pedido: numberToReal(line.menor_pedido),
            qtd_pedidos: line.qtd_pedidos,
            receita: numberToReal(line.valor_vendido),
            "ticket médio": numberToReal(line.ticket_medio),
          });
        });
      }

      let data = {
        title: {
          top: "",
          name: "Detalhes por Canal",
        },
        items,
      };
      return (this.tableData = data);
    },
  },
  watch: {
    getGraphicLine(data) {
      this.loadGraphicLine(data);
    },
    getAffiliate(data) {
      if (data) {
        this.loadGraphicBar(data);
        this.loadTable(data);
      }
    },
    currentPage(newPage) {
      this.currentPage = newPage;
      this.listarVendasPorCanal(this.start, this.end, this.perPage, this.currentPage);
    },
    accountActive(data) {
      if (data) {
        this.listarVendasPorCanal(this.start, this.start, this.perPage, this.currentPage);
        this.listarVendasPlataformaMarketplacePorPeriodo();
      }
    },
  },
};
</script>

<style lang="scss" >
.box-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.categorias {
  height: 100%;
  .page-container {
    margin: 30px 0;
  }
}

.small {
  max-width: 600px;
  margin: 150px auto;
}

.primary {
  display: flex;
  flex-wrap: wrap;
}

.box {
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  max-width: calc(50% - 7.5px);
  width: 100%;
  position: relative;
  background-color: #fff;
  padding: 18px;
  box-shadow: 0 3px 6px #c3d0e029;
  border-radius: 20px;
  height: 400px;
  &:nth-child(2) {
    margin-right: 0;
  }
  &__header {
    display: flex;
    justify-content: space-between;
  }
  .painel {
    position: relative;
    height: 300px;
    display: block;
    overflow-x: auto;
    .table {
      th[aria-colindex="2"],
      td[aria-colindex="2"],
      th[aria-colindex="3"],
      td[aria-colindex="3"],
      th[aria-colindex="5"],
      td[aria-colindex="5"],
      th[aria-colindex="6"],
      td[aria-colindex="6"] {
        white-space: nowrap;
        text-align: right;
      }
      th[aria-colindex="4"],
      td[aria-colindex="4"] {
        white-space: nowrap;
        text-align: center;
      }
    }
  }
  &.table {
    margin: 0;
    margin-top: 15px;
    max-width: calc(100%);
    height: auto;
    &:after {
      content: "";
      display: block;
    }
    .painel {
      height: auto;
    }
  }
}

@media only screen and (max-width: 991px) {
  .box-filter {
    justify-content: flex-end;
  }
  .box {
    max-width: 100%;
    margin-top: 30px;
    margin-right: 0;
    padding: 25px;
    &.table {
      margin-top: 30px;
    }
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
