import client from "@/api";
import store from "@/store";
import { _rotaVtexAfiliadosList, _rotaVtexAfiliadosValor, _rotaVtexAfiliadosVtexFulfillmentPeriod, _rotaGetCSVAfiliados } from "@/api/_caminhosApi";

export function getCSV(from, to) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .get(_rotaGetCSVAfiliados(), {
      params: {
        from,
        to,
      },
      responseType: "blob",
    })
    .then(resp => {
      if (resp.status === 200) {
        const url = window.URL.createObjectURL(new Blob([resp.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "affiliates.csv");
        document.body.appendChild(link);
        link.click();
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function listarVendasPorCanal(from, to, qtd, page) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("vendas/RESET_VENDAS_CANAL", null);
  client
    .get(`${_rotaVtexAfiliadosValor()}`, {
      params: {
        from,
        to,
        per_page: qtd,
        page: page,
      },
    })
    .then(resp => {
      if (resp.status === 200) {
        store.commit("vendas/SET_VENDAS_CANAL", resp.data);
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function listarVendasPlataformaMarketplacePorPeriodo(from, to) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("vendas/RESET_VENDAS_VTEX_FULFILLMENT_PERIODO", null);
  client
    .get(`${_rotaVtexAfiliadosVtexFulfillmentPeriod()}`, {
      params: {
        from,
        to,
      },
    })
    .then(resp => {
      if (resp.status === 200) {
        store.commit("vendas/SET_VENDAS_VTEX_FULFILLMENT_PERIODO", resp.data);
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function getAffiliatesList() {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("vendas/RESET_AFFILATE_LIST", null);
  client
    .get(`${_rotaVtexAfiliadosList()}`)
    .then(resp => {
      if (resp.status === 200) {
        store.commit("vendas/SET_AFFILATE_LIST", resp.data);
      }
    })
    .catch(erro => {
      console.log(erro);
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}