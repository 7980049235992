<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
// import { numberToReal } from "@/utils/money";
import { numberToRealWitoutSymbol } from '../../utils/money';

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: ["chartData"],
  methods: {
    loadChart() {
      this.renderChart(this.chartData, {
        tooltips: {
          callbacks: {
            label: (value) => numberToRealWitoutSymbol(value.yLabel),
            // label: function (value) {
            //   return numberToReal(value.yLabel);
            // },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        responsive: true,
        maintainAspectRatio: false,
      });
    },
  },
  mounted() {
    this.loadChart();
  },
  watch: {
    chartData() {
      this.loadChart();
    }
  },
};
</script>

<style lang="scss" scoped>
div {
  position: absolute;
  height: 100%;
  width: 100%;
}
</style>
